$c-primary: #78be20;
$c-secandary: #e30000;
$dots: false;
$body-color-overrided: #004833;
$loading-bg-overrided: #78be20;
$dot-overrided: #e30000;
$progress-bar-overided: white;
$progress-bar-incomplete-overided: #000;
$base-font-family: 'Rubik', sans-serif;
$font-family-heading: 'Rubik', sans-serif;


            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  background: rgba(0, 0, 0, 0.7);
  height: 0;
  overflow: hidden;
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;

  &.open {
    height: auto;
    opacity: 1;
  }

  .modalContent {
    outline: none;
    opacity: 0;
    width: 90%;
    max-width: 520px;
    -webkit-transform: translateY(3%);
    transform: translateY(3%);
    -webkit-transition: opacity 0.2s, -webkit-transform 0.2s;
    transition: opacity 0.2s, -webkit-transform 0.2s;
    transition: opacity 0.2s, transform 0.2s;
    transition: opacity 0.2s, transform 0.2s, -webkit-transform 0.2s;
    max-height: calc(90vh - 1.5rem);

    &:after {
      content: '';
      display: block;
      height: 10vh;
    }

    &.open {
      opacity: 1;
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }

    .dialog {
      min-width: 280px;
      padding: 1.25rem 1.25rem 0;
      border-radius: 0.5rem;
      background: var(--page-background-color);
      box-shadow: none;
      font-size: 1rem;
      line-height: 1.3125;
      .heading {
        margin: 1rem 0;
        text-align: center;
        font-weight: 500;
        font-size: 1.125rem;
        line-height: 1.333;

        div {
          margin-bottom: 1.5rem;
          width: 2.75rem; //44
          height: 2.75rem; //44
        }

        span {
          font-size: 1rem;
          display: block;
        }
      }

      .footer {
        width: 100%;
        height: 4.8125rem; //45+16*2=77
        margin-top: 1rem; //16
        padding: 1rem 0; //16
        overflow: hidden;
        z-index: 2;
      }
    }

    &.small {
      .dialog {
        max-width: 310px;
        min-width: inherit;
        margin: auto;
      }
    }
  }
}

@media (max-width: 760px) {
  .wrapper {
    .dialog {
      min-width: 100%;
    }
  }
}
