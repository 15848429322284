$c-primary: #78be20;
$c-secandary: #e30000;
$dots: false;
$body-color-overrided: #004833;
$loading-bg-overrided: #78be20;
$dot-overrided: #e30000;
$progress-bar-overided: white;
$progress-bar-incomplete-overided: #000;
$base-font-family: 'Rubik', sans-serif;
$font-family-heading: 'Rubik', sans-serif;


            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  .wrapper_default {
    display: flex;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
    flex-direction: column;
    align-content: center;
    background-color: var(--page-background-color);
    font-size: 1rem;
    line-height: 1.375;
    letter-spacing: -0.01875rem; //-0.332143px;
    z-index: 34;
  }

  .content {
    padding: 2rem;
  }
  
  .title {
    font-size: var(--base-font-size) !important;
  }

  .footer {
    font-size: var(--base-font-size) !important;
    text-align: center;
  }

  .animation-container {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    width: 100%;
    flex: 1;
    overflow: hidden;
    background-color: var(--page-background-color);
  }

  .spinner1 {
    margin-bottom: 40px;
    margin-left: 23px;
    border-radius: 100%;
    width: 25px;
    height: 25px;
    box-shadow: 24px 24px var(--loading-spinner-dot1), -24px 24px var(--loading-spinner-dot2),
      -24px -24px var(--loading-spinner-dot3), 24px -24px var(--loading-spinner-dot4);
    animation: spin ease infinite 2s;
  }
  @keyframes spin {
    0%,
    100% {
      box-shadow: 24px 24px var(--loading-spinner-dot1), -24px 24px var(--loading-spinner-dot2),
        -24px -24px var(--loading-spinner-dot3), 24px -24px var(--loading-spinner-dot4);
    }
    25% {
      box-shadow: -24px 24px var(--loading-spinner-dot4), -24px -24px var(--loading-spinner-dot1),
        24px -24px var(--loading-spinner-dot2), 24px 24px var(--loading-spinner-dot3);
    }
    50% {
      box-shadow: -24px -24px var(--loading-spinner-dot3), 24px -24px var(--loading-spinner-dot4),
        24px 24px var(--loading-spinner-dot1), -24px 24px var(--loading-spinner-dot2);
    }
    75% {
      box-shadow: 24px -24px var(--loading-spinner-dot2), 24px 24px var(--loading-spinner-dot3),
        -24px 24px var(--loading-spinner-dot4), -24px -24px var(--loading-spinner-dot1);
    }
  }
}
