$c-primary: #78be20;
$c-secandary: #e30000;
$dots: false;
$body-color-overrided: #004833;
$loading-bg-overrided: #78be20;
$dot-overrided: #e30000;
$progress-bar-overided: white;
$progress-bar-incomplete-overided: #000;
$base-font-family: 'Rubik', sans-serif;
$font-family-heading: 'Rubik', sans-serif;


            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
$arrow-x: 25%;

.wrapper {
  text-align: center;
  position: fixed;
  width: 100%;
  height: 100%;
  background: #000;
  letter-spacing: 0.4px;
  z-index: 999999;
  display: flex;
  flex-direction: column;

  .title {
    color: #fff;
    font-size: 14px;
    padding-top: 20px;
    width: 70%;
    margin: 0 auto;

    img {
      display: block;
      margin: 0 auto 10px;
    }
  }

  .popup {
    flex: 1;
    max-height: 35%;
  }

  .helper {
    position: relative;
    width: 337px;
    height: 168px;
    left: $arrow-x;
    background-image: url(_img('liveness/bg-min.png'));
    background-repeat: no-repeat;
    animation: showMe 0s 1.7s forwards;
    opacity: 0;

    .arrow {
      width: 43px;
      height: 86px;
      position: absolute;
      right: 0;
      left: 0;
      top: 25px;
      margin: auto;
      animation-name: bounce;
      animation-duration: 1.5s;
      animation-iteration-count: infinite;

      &::before {
        content: '';
        width: 2px;
        background: #fff;
        margin: auto;
        left: 0;
        right: 0;
        position: absolute;
        height: 100%;
      }

      &::after {
        content: '';
        border-width: 2px;
        border-color: white;
        border-left-style: solid;
        border-top-style: solid;
        position: absolute;
        width: 30px;
        height: 30px;
        left: 0;
        right: 0;
        top: 5px;
        margin: auto;
        transform: rotate(45deg);
      }
    }
  }

  &.chrome {
    .popup {
      max-height: 33%;
    }

    .helper {
      left: 44.5%;
    }
  }

  &.samsung {
    .helper {
      // left: 45%;
      // transform: scale(1, -1);
      // top: -148px;
      display: none;
    }
  }

  &.firefox {
    .helper {
      display: none;
    }
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-30px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes showMe {
  to {
    opacity: 1;
  }
}
