$c-primary: #78be20;
$c-secandary: #e30000;
$dots: false;
$body-color-overrided: #004833;
$loading-bg-overrided: #78be20;
$dot-overrided: #e30000;
$progress-bar-overided: white;
$progress-bar-incomplete-overided: #000;
$base-font-family: 'Rubik', sans-serif;
$font-family-heading: 'Rubik', sans-serif;


            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.content {
  word-break: break-word;

  a {
    font-style: italic;
  }
}
