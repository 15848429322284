$c-primary: #78be20;
$c-secandary: #e30000;
$dots: false;
$body-color-overrided: #004833;
$loading-bg-overrided: #78be20;
$dot-overrided: #e30000;
$progress-bar-overided: white;
$progress-bar-incomplete-overided: #000;
$base-font-family: 'Rubik', sans-serif;
$font-family-heading: 'Rubik', sans-serif;


            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.heading {
  font-family: var(--heading-font-family);
  color: var(--heading-text-color);
  margin-bottom: 1.5rem; //24
  text-align: left;
  font-weight: 600;
  font-size: var(--heading-font-size);
  line-height: 1.1;
  letter-spacing: -0.03125rem; //-0.5px
}
